import * as React from "react";
import Content from "../contentcontainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Thanks = () => {
  return (
    <Content>
      <h2 id="about" className="text-center text-4xl pt-10">
        Thank You
      </h2>
      <h2 className="flex-none w-8 text-4xl mx-auto pt-10">
        <FontAwesomeIcon icon={faCheckCircle} />
      </h2>
      <p className="my-10">
      Thank you for visiting our website. At Semaforce, we are dedicated to providing the best possible service to our clients. If you have any questions or would like to learn more about our services, please don't hesitate to contact us.
        You can fill out the contact form on this page and one of our representatives will get back to you as soon as possible.
        Thank you for considering Semaforce for your DevOps and security needs. We look forward to hearing from you.{" "}
      </p>
    </Content>
  );
};

export default Thanks;
